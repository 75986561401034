html {
    font-size: 62.5%;
}

body {
    height: calc(100vh - env(safe-area-inset-bottom));
    padding-bottom: env(safe-area-inset-bottom);
    background-color: #0a373a !important;
    margin: 0;
    touch-action: manipulation;
    font-family: 'IBMPlexSans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-overflow-scrolling: touch;
}

.innerHtmlStyles {
    color: #333333;
    font-family: 'IBM Plex Sans', monospace;
    font-size: 17px;
}

u {
    color: #FF8C65;
}

