.App {
    text-align: center;
    height: calc(100vh - env(safe-area-inset-bottom, 0px) - env(safe-area-inset-top, 0px)) !important;
    overscroll-behavior-y: none;
    overflow: hidden;
}

div {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
}

/* width */
@media (min-width: 1025px) {
    ::-webkit-scrollbar {
        width: 5px;
        height: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #e5e5e5;
        border-radius: 20px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #b2b2b2;
        border-radius: 20px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #4c4c4c;
    }
}